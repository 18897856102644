// import Inputmask from "inputmask";

const add_error = (item) => {
    item.closest('.feedback-line').classList.add('feedback-line_error');
}

const validate = (collection) => {
    let valid = true;
    collection.forEach((item) => {
        const re = new RegExp(item.dataset.regexp, 'gi');
        const val = re.test(item.value);

        if (!val) {
            add_error(item);
        }
        valid = valid && val;
    });
    return valid;
}

const feedback = () => {
    const form = document.getElementById('feedback-form');
    if (!form) {
        return;
    }

    const subm = form.querySelector('button[type="submit"]');

    const collection = Array.from(form.querySelectorAll('input[type="text"], input[type="email"], textarea'));

    collection.forEach((x) => {
        x.addEventListener('focus', function() {
            const parent = this.closest('.feedback-line');
            parent.classList.add('focused');
            parent.classList.remove('feedback-line_error');
        });
        x.addEventListener('blur', function() {
            if (this.value === '') {
                this.closest('.feedback-line').classList.remove('focused');
            }
        });
    });

    // const tel_input = document.getElementById("tel");
    // const im = Inputmask({
    //     'mask': '+380 99 999-99-99',
    // }).mask(tel_input);


    const reset_form = () => {
        collection.forEach(x => x.value = '');
        subm.disabled = false;
    };


    form.addEventListener("ajax:before", (event) => {
        subm.disabled = true;
        if (!validate(collection)) {
            event.preventDefault();
            subm.disabled = false;
        }
    });

    form.addEventListener("ajax:success", (event) => {
        const [data, status, xhr] = event.detail;
        if (data.type === 'feedback') {
            document.body.classList.add('popup-active', 'popup-feedback');
            reset_form();
        }
    });
}

export default feedback;
