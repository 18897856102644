

// import Swiper bundle with all modules installed
import Swiper from 'swiper/bundle';

const slider = () => {
    const slider = new Swiper('.reviewSwiper', {
        // slidesPerView: 3,
        slidesPerView: 'auto',
        // spaceBetween: 72,
        scrollbar: {
            el: ".swiper-scrollbar",
            hide: false,
            // dragSize: 'auto',
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        breakpoints: {
            320: {
                spaceBetween: 16,
            },
            768: {
                spaceBetween: 48,
            },
            1280: {
                spaceBetween: 84,
            },
        },
    });
};

export default slider;
