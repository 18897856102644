import LocomotiveScroll from 'locomotive-scroll';

const scroll_animation = () => {
    const container = document.querySelector('[data-scroll-container]');
    if (!container) {
        return;
    }

    const scroll = new LocomotiveScroll({
        el: container,
        smooth: false,
        resetNativeScroll: false,
        repeat: true,
    });

    (() => {
        const star = document.querySelector('.leader-start');
        if (!star) {
            return;
        }
        let distance = 360 * 2;
        // let degress = 0;
        // const step = 1;
        scroll.on('scroll', (args) => {
            if (typeof args.currentElements['leader'] === 'object') {
                let obj = args.currentElements['leader'];
                // console.log(obj.progress * distance);
                // console.log(obj.progress);
                star.style.transform = `rotate(${obj.progress * distance}deg)`;
            }
        });
    })();

}

export default scroll_animation;
