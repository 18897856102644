import KUTE from 'kute.js';
import throttle from 'lodash/throttle';

const get_window_height = () => {
    return document.documentElement.clientHeight;
};

const waves = () => {
    /*
    const variants = [
        "M-1.69,0-3.87,50C80.13,51,78,65,250,65c177,0,176-12,451-38V-1.56C620-1.56,176.31,0-1.69,0Z",
        "M-1-1V70c84,1,211.3-14.7,390-39,125-17,272-10,312-8V0C620,0,177-1-1-1Z",
        "M-1.6,0V53C24.4,48,176,17,306,17c202,0,355,36,395,38V0Z",
        "M0,0V16c26-5,52.07-5.94,83.35-5.94C270.52,10.06,495,82,701,73V0Z",
        "M-2.14,0V39c37,11,115.33,19.39,142.1,24,273,47,420-7,561-26V0Z",
        "M-2.14,0V39s37.25,16.58,64,21.19c273,47,270.26-29.42,639.12-23.15V0Z",
        "M-2.14,0V9H33.55C115.28,9,332.14,30.77,701,37V0Z",
        "M-2.14,0V-10.37S-2-6.28,33.55-6.28c81.73,0,298.59-10.36,667.45-4.09V0Z"];
    */
    const variants = [
        "m 0,0 v 51.55999 c 83.89632,0.99999 81.76894,14.99999 253.55669,14.99999 176.78152,0 175.78276,-12 450.44331,-37.99999 V 0 C 623.09998,0 177.78034,0 0,0 Z",
        "m 1e-5,2e-5 v 71 c 83.81618,1 210.83758,-14.7 389.14649,-39 124.72644,-17 274.94104,-9.48779 314.8535,-7.48779 L 703.71156,0.0492 C 622.88883,0.0492 177.61047,0 10e-6,0 Z",
        "M 0,0 V 53 C 26.05181,48 177.95387,17 308.21293,17 510.61538,17 663.92029,53 704,55 V 0 Z",
        "m 1e-5,0 v 16 c 26.11127,-5 52.29284,-5.94 83.70671,-5.94 187.97103,0 413.41172,71.94001 620.29327,62.94001 V 0 Z",
        "M 0,0 V 39 C 37.04736,50 115.47766,58.39 142.28192,63 415.63136,110 562.81952,56 704,37 V 0 Z",
        "m 2e-5,0 v 39 c 0,0 37.29662,16.58 64.08009,21.19 273.34167,47 270.59827,-29.42 639.91987,-23.15 V 0 Z"
    ];

    let index = 0;
    let tween;
    let WAIT = true;

    let window_height = get_window_height();

    const path_orange = document.querySelector('.wave-bg-orange');
    const path_black = document.querySelector('.wave-bg-black');
    const path_pink = document.querySelector('.wave-bg-pink');

    if (!path_orange || !path_black || !path_pink) {
        return;
    }

    const animate = () => {
        if (!WAIT) {
            return;
        }

        let p_orange = path_orange.getBoundingClientRect();
        let p_black = path_black.getBoundingClientRect();
        let p_pink = path_pink.getBoundingClientRect();

        let svg = '';

        if (p_orange.top < window_height && p_orange.bottom > 0) {
            svg = '#desktopWave__orange';
        } else if (p_black.top < window_height && p_black.bottom > 0) {
            svg = '#desktopWave__black';
        } else if (p_pink.top < window_height && p_pink.bottom > 0) {
            svg = '#desktopWave__pink';
        } else {
            svg = '';
            index = 0;
            return;
        }

        if (variants[index]) {
            tween = KUTE.to(svg,
                { path: variants[index] },
                {
                    duration: 100,
                    // easing: 'easingCubicInOut'
                    onStart: () => {
                        WAIT = false;
                    },
                    onComplete: () => {
                        WAIT = true;
                        index += 1;
                    }
                }
            ).start();
        } else {
            index = 0;
        }
    }

    window.addEventListener('scroll', throttle(animate, 50));

    window.addEventListener('resize', throttle(() => {
        window_height = get_window_height();
    }, 100));

}

export default waves;
