
let box;

const getCoords = (elem) => {
    let box = elem.getBoundingClientRect();
    return {
        top: box.top + pageYOffset,
        left: box.left + pageXOffset
    };
}



const nav_open = (node) => {
    node.classList.add('nav-open');
}

const nav_close = (node) => {
    node.classList.remove('nav-open');
}

const set_nav_event = (event) => {
    const target = event.target.classList.contains('.nav-link') ? event.target : event.target.closest('.nav-link');
    const node = document.querySelector(`a[name="${target.dataset.href}"]`);

    if(!node) {
        return;
    }

    event.preventDefault();
    history.replaceState(null, document.title, target.href);
    nav_close(box);
    window.scrollTo({
        top: getCoords(node).top,
        behavior: 'smooth'
    });
}


const navigation = () => {
    const btn_open = document.querySelector('.sandwith');
    const btn_close = document.querySelector('.sandwith-close');
    box = document.querySelector('.nav-point');

    if (box && btn_open && btn_close) {
        btn_open.addEventListener('click', () => {
            nav_open(box);
        });
        btn_close.addEventListener('click', () => {
            nav_close(box);
        });
    }

    document.querySelectorAll('.nav-link').forEach((x) => {
        x.addEventListener('click', set_nav_event);
    })
};

export default navigation;
