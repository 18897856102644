import Inputmask from "inputmask";

let form;
let new_order = true;
let result = {};


async function postData(url = '', data = {}) {
    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
            'X-Requested-With': 'XMLHttpRequest',
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        // body: JSON.stringify(data)
        body: data,
    });
    return await response.json();
}


const send_data = () => {
    const data = new FormData(document.querySelector('.order-form'));
    const url = new_order ? '/order/create' : '/order/edit';
    postData(url, data).then((response) => {
        result['id'].innerText = response.order_id;
        result['quant'].innerText = response.quant;
        result['user'].innerText = response.username;
		result['user_last'].innerText = response.username_last;
        result['tel'].innerText = response.tel;
        result['email'].innerText = response.email;
        result['sum'].innerText = response.summ;

        const address_box = document.querySelector('.order-confirm-grid');

        if (response.autograph == '1') {
            result['autograph'].style.opacity = '1';
        } else {
            result['autograph'].style.opacity = '0';
        }

        if (response.delivery === 'newpost') {
            address_box.classList.remove('show-addr');
            address_box.classList.add('show-np');
            result['np'].innerText = `${response.city}, ${response.department}`;
        } else {
            address_box.classList.remove('show-np');
            address_box.classList.add('show-addr');

            let address = `${response.street}, ${response.house}`;
            if (result.appart !== '') {
                address += `, ${response.appart}`;
            }
            result['addr'].innerText = address;
        }

        const viewort = document.querySelector('.form-viewort');
        viewort.classList.remove('form-view-1', 'wait');
        viewort.classList.add('form-view-2');
    });
}


const add_error = (item) => {
    item.closest('.order-form-col').classList.add('order-form-col_error');
}

const validate = (collection) => {
    let valid = true;
    collection.forEach((item) => {
        const re = new RegExp(item.dataset.regexp, 'gi');
        const val = re.test(item.value);

        if (!val) {
            add_error(item);
        }
        valid &&= val;
    });
    return valid;
}


const check_slide_zero = () => {
    if (form['delivery'].value === 'courier') {
        return validate([
            form['street'],
            form['house'],
        ]);
    }
    return true;
}

const check_slide_one = () => {
    return validate([
        form['username'],
		form['username_last'],
        form['tel'],
        form['email'],
    ]);
}

const get_delivery_method = () => {
    return form.querySelector('input[name="delivery"]:checked').value;
}

const set_delivery_method = (value) => {
    const box = form.querySelector('.delivery-section');
    box.classList.remove('delivery-section-view-newpost', 'delivery-section-view-courier');
    box.classList.add(`delivery-section-view-${value}`);
}

const calc_price = () => {
    form.querySelector('#summ').innerText = parseInt(form['quant'].value, 10) * (form['autograph'].value === '1' ? pricelist.book_ext : pricelist.book) + (form['delivery'].value === 'courier' ? pricelist.delivery : 0);
}


const checkers = [
    check_slide_zero,
    check_slide_one,
];


const form_order_events = () => {
    form = document.querySelector('.order-form');
    if (!form) {
        return;
    }

    ['id', 'quant', 'quant', 'user', 'user_last', 'tel', 'email', 'np', 'addr', 'autograph', 'sum'].forEach((x) => {
        result[x] = document.querySelector(`#res-${x}`);
    })

    const counter = document.querySelector('#counter');
    const viewort = document.querySelector('.form-viewort');
    const next_buttons = document.querySelectorAll('.next-btn');


    next_buttons.forEach((x) => x.addEventListener('click', function(){
        const index = parseInt(this.dataset.index, 10);
        if (checkers[index]()) {
            if (index === 1) {
                viewort.classList.add('wait');
                // viewort.classList.remove(`form-view-${index}`);
                // counter.innerText = index + 2;
                form.scrollIntoView();
                send_data();
            } else {
                counter.innerText = index + 2;
                viewort.classList.replace(`form-view-${index}`, `form-view-${index + 1}`);
                form.scrollIntoView();
            }

        }
    }));

    const tel_input = document.getElementById("order-tel");
    const im = Inputmask({
        'mask': '+380 99 999-99-99',
        showMaskOnHover: false,
    }).mask(tel_input);

    form.querySelectorAll('input, textarea').forEach((x) => {
        x.addEventListener('focus', function() {
            this.closest('.order-form-col').classList.remove('order-form-col_error');
        });
    });

    set_delivery_method(get_delivery_method());

    form.querySelectorAll('input[name="delivery"]').forEach((x) => {
        x.addEventListener('click', function(){
            set_delivery_method(this.value);
            calc_price();
        });
    });

    // form['autograph'].addEventListener('click', calc_price);
    form['autograph'].addEventListener('change', calc_price);
    form['quant'].addEventListener('input', calc_price);

    calc_price();

    document.querySelectorAll('.edit-bth').forEach((x) => {
        x.addEventListener('click', function(){
            new_order = false;
            viewort.classList.replace('form-view-2', `form-view-${this.dataset.target}`);
            form.scrollIntoView();
        })
    });

    const quant_input = document.getElementById("quant");
    const qim = Inputmask({
        'mask': '9{1,3}',
        showMaskOnHover: false,
        placeholder: '',
    }).mask(quant_input);

    quant_input.addEventListener('input', function(){
        if (this.value == '0' || this.value == '') {
            this.value = 1;
            calc_price();
        }
    });

    document.querySelectorAll('.quantity-btn').forEach((node) => {
        node.addEventListener('click', function(){
            const self = this;
            const value = parseInt(quant_input.value, 10);
            if (this.dataset.event === 'minus' && value > 1) {
                quant_input.value = value - 1;
                calc_price();
            } else if (this.dataset.event === 'plus') {
                quant_input.value = value + 1;
                calc_price();
            }
        });
    });



}

export default form_order_events;
