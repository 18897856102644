// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
// import * as ActiveStorage from "@rails/activestorage"
import "channels"

import Choices from 'choices.js';

import feedback from './form_feedback';
import form_order_events from './form_order_events';
import navigation from './navigation';
import scroll_animation from './scroll-animation';
import slider from './slider';
import waves from './waves';


Rails.start()
Turbolinks.start()
// ActiveStorage.start()

document.addEventListener("turbolinks:load", () => {
// window.addEventListener("load", () => {

    feedback();
    form_order_events();
    navigation();
    scroll_animation();
    slider();
    waves();


    const popup_close = document.querySelector('.message-popup-btn');
    if (popup_close) {
        popup_close.addEventListener('click', () => {
            if (window.location.search.indexOf('complete=1') >= 0) {
                history.replaceState(null, document.title, '/');
            }
            document.body.classList.remove('popup-active', 'popup-feedback', 'popup-payment')
        });
    }

    // Choices
    const options = {
        /*
        loadingText: 'Loading...',
        noResultsText: 'No results found',
        noChoicesText: 'No choices to choose from',
        itemSelectText: 'Press to select',
        /*/
        loadingText: '',
        noResultsText: 'Нічого не знайдено',
        noChoicesText: '',
        itemSelectText: '',
        //*/
        shouldSort: false,
    };

    // const select_quantity = document.querySelector('#quant');
    // if (select_quantity) {
    //     const quantity = new Choices(select_quantity, Object.assign({...options }, {
    //         searchEnabled: false,
    //     }));
    // }

    const select_autograph = document.querySelector('#autograph');
    if (select_autograph) {
        const autograph = new Choices(select_autograph, Object.assign({...options }, {
            searchEnabled: false,
        }));
    }


    const select_city = document.querySelector('#city');
    if (select_city) {
        const city = new Choices(select_city, options);
    }

    const select_department = document.querySelector('#department');
    let department;
    if (select_department) {
        department = new Choices(select_department, options);
    }

    function get_np_departments() {
        var opts = { method: 'GET', headers: {} };
        fetch('/get_np_offices?city_id=' + document.getElementById('city').value, opts).then(function(response) {
                return response.json();
            })
            .then(function(body) {
                // console.log(body);
                let new_choices = [];
                department.clearChoices();
                //data = JSON.parse(body);
                var i = 0;
                for (let elem of body) {
                    // console.log(elem);
                    if (i == 0) {
                        new_choices.push({
                            value: elem.number,
                            label: elem.text,
                            //label: "Відділення №" + elem.number,
                            selected: true
                        });
                    } else {
                        new_choices.push({
                            value: elem.number,
                            label: elem.text,
                            // label: "Відділення №" + elem.number,

                        });
                    }
                    i++;
                }
                department.setChoices(new_choices);
            });
    }

    if (select_city) {
        select_city.addEventListener('change', function() {
            get_np_departments();
        })
        get_np_departments();
    }
});
